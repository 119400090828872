import React from "react";

export interface ITipSectionProps {
    children: React.ReactNode | React.ReactNode[];
}

export const TipSection = ({ children }: ITipSectionProps) => {
    return (
        // <div className="border border-rose-600 border-solid rounded-md">
        <div className="mb-5 rounded-md bg-white p-10 border">{children}</div>
    );
};
