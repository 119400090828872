import { type VariantProps } from "class-variance-authority";
import * as React from "react";
import { badgeVariants } from "./badgeVariants";

import { classMerge } from "../../utils/styleUtils";

export interface IBadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

/**
 * Displays a badge or a component that looks like a badge.
 *
 * @see https://ui.shadcn.com/docs/components/badge
 */
function Badge({ className, variant, size, ...props }: IBadgeProps) {
    return (
        <div
            className={classMerge(badgeVariants({ variant, size }), className)}
            {...props}
        />
    );
}

export { Badge };
