import { Button, Card, CardContent, Flex, LogoWorck } from "@worck/ui";
import { useNavigate, useParams } from "react-router-dom";
import { TipSectionHeading } from "../components/TipSectionHeading";
import { useGetTipsterVacancy } from "../hooks";

export const TipLeadItemIntroPage = () => {
    const navigate = useNavigate();
    const { tipsterVacancyShortCode } = useParams();
    const { data: tipsterVacancy } = useGetTipsterVacancy(
        tipsterVacancyShortCode || "",
        {
            enabled: !!tipsterVacancyShortCode,
        }
    );
    const maxBonusAmount = tipsterVacancy?.vacancy_bonus_amount || 0;

    const formatMoney = (amount: number) => {
        return new Intl.NumberFormat("nl", {
            style: "currency",
            currency: "EUR",
        }).format(amount / 100);
    };

    const handleNextStepClick = () => {
        if (tipsterVacancyShortCode) {
            navigate(`/tip/${tipsterVacancyShortCode}`);
        }
    };

    return (
        <div className="flex flex-col h-full px-4">
            <div className="w-full text-center my-12 px-4">
                <LogoWorck className="w-48" />
            </div>

            <Card className="max-w-xl mx-auto flex justify-start flex-col gap-y-6 mb-5 rounded-md bg-white p-10 border">
                <CardContent className="flex items-center flex-col h-full">
                    {/* <RewardIndicator bonus={formatMoney(maxBonusAmount)} />
                    <div className="flex flex-col gap-y-0 ml-3">
                        <h3 className="text-primary font-bold text-lg">
                            Jouw bonus!
                        </h3>
                        <p className="mt-2 max-w-4xl text-gray-500 text-sm leading-6 font-light">
                            Hoe completer je de informatie invult, hoe hoger de
                            de beloning
                        </p>
                    </div> */}

                    <Flex className="align-center">
                        <div className="max-w-xl mx-auto flex justify-start flex-col gap-y-6">
                            <TipSectionHeading
                                title={`Hoi ${tipsterVacancy?.name},`}
                                className="border-0 pb-0 mb-0"
                            />
                            {/* <p className="font-semibold text-gray-500 leading-6 text-lg text-gray-500">
                                Hoi {tipsterVacancy?.name},
                            </p> */}
                            <p className="font-light text-gray-500 leading-6 text-base">
                                Help je netwerk aan een nieuwe uitdaging én
                                jezelf aan een mooie beloning!
                                <br />
                                <br />
                                Leuk dat je mee wilt helpen met het invullen van
                                vacature "{tipsterVacancy?.vacancy_title}". Bij
                                een succesvolle match ontvang je een maximale
                                beloning van{" "}
                                <span className="font-semibold">
                                    {formatMoney(maxBonusAmount)}
                                </span>
                            </p>
                            {/* <p>
                                <TipRewardBadge>Dikke tip</TipRewardBadge>
                            </p> */}
                        </div>
                    </Flex>
                </CardContent>
            </Card>

            <div className="max-w-xl mx-auto flex gap-y-6 border-none w-full">
                <Button
                    onClick={handleNextStepClick}
                    className="gap-x-2 mt-10 mb-20 w-full"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        color="#fff"
                        fill="none"
                    >
                        <path
                            opacity="0.4"
                            d="M5.53327 17C4.11143 19.0062 3.75309 20.0652 4.15321 20.9156C4.19286 20.9999 4.23928 21.0812 4.29207 21.1589C4.86372 22 6.34111 22 9.2959 22H14.7041C17.6589 22 19.1363 22 19.7079 21.1589C19.7607 21.0812 19.8071 20.9999 19.8468 20.9156C20.2469 20.0652 19.8891 19.0062 18.4673 17"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12.9981 7H11.0019C8.13196 7 6.19701 10.0691 7.32753 12.828C7.48501 13.2124 7.84633 13.4615 8.24612 13.4615H8.9491C9.18605 13.4615 9.39259 13.6302 9.45006 13.8706L10.3551 17.6567C10.5438 18.4462 11.222 19 12 19C12.778 19 13.4562 18.4462 13.6449 17.6567L14.5499 13.8706C14.6074 13.6302 14.814 13.4615 15.0509 13.4615H15.7539C16.1537 13.4615 16.515 13.2124 16.6725 12.828C17.803 10.0691 15.868 7 12.9981 7Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M14.5 4.5C14.5 5.88071 13.3807 7 12 7C10.6193 7 9.5 5.88071 9.5 4.5C9.5 3.11929 10.6193 2 12 2C13.3807 2 14.5 3.11929 14.5 4.5Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                        />
                    </svg>
                    Ik weet een goede candidate
                </Button>
            </div>
        </div>
    );
};
