"use client";

import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";

import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";
import { buttonVariants } from "../button/buttonVariants";

/**
 * A modal dialog that interrupts the user with important content and expects a response.
 *
 * @see https://ui.shadcn.com/docs/components/alert-dialog
 */
const AlertDialog = AlertDialogPrimitive.Root;

const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

const AlertDialogPortal = AlertDialogPrimitive.Portal;

export type TALertDialogOverlayProps = React.ComponentPropsWithoutRef<
    typeof AlertDialogPrimitive.Overlay
>;

const AlertDialogOverlay: FC<TALertDialogOverlayProps> = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
    TALertDialogOverlayProps
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ className, children, ...props }, ref) => (
    <AlertDialogPrimitive.Overlay
        className={classMerge(
            "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
            className
        )}
        {...props}
        ref={ref}
    />
));
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;

export type TAlertDialogContentProps = React.ComponentPropsWithoutRef<
    typeof AlertDialogPrimitive.Content
>;

const AlertDialogContent: FC<TAlertDialogContentProps> = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Content>,
    TAlertDialogContentProps
>(({ className, ...props }, ref) => (
    <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogPrimitive.Content
            ref={ref}
            className={classMerge(
                "fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full",
                className
            )}
            {...props}
        />
    </AlertDialogPortal>
));
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;

const AlertDialogHeader = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={classMerge(
            "flex flex-col space-y-2 text-center sm:text-left",
            className
        )}
        {...props}
    />
);
AlertDialogHeader.displayName = "AlertDialogHeader";

const AlertDialogFooter = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={classMerge(
            "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
            className
        )}
        {...props}
    />
);
AlertDialogFooter.displayName = "AlertDialogFooter";

export type TAlertDialogTitleProps = React.ComponentPropsWithoutRef<
    typeof AlertDialogPrimitive.Title
>;

const AlertDialogTitle: FC<TAlertDialogTitleProps> = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Title>,
    TAlertDialogTitleProps
>(({ className, ...props }, ref) => (
    <AlertDialogPrimitive.Title
        ref={ref}
        className={classMerge("text-lg font-semibold", className)}
        {...props}
    />
));
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;

export type TAlertDialogDescriptionProps = React.ComponentPropsWithoutRef<
    typeof AlertDialogPrimitive.Description
>;

const AlertDialogDescription: FC<TAlertDialogDescriptionProps> =
    React.forwardRef<
        React.ElementRef<typeof AlertDialogPrimitive.Description>,
        TAlertDialogDescriptionProps
    >(({ className, ...props }, ref) => (
        <AlertDialogPrimitive.Description
            ref={ref}
            className={classMerge("text-sm text-muted-foreground", className)}
            {...props}
        />
    ));
AlertDialogDescription.displayName =
    AlertDialogPrimitive.Description.displayName;

export type TAlertDialogActionProps = React.ComponentPropsWithoutRef<
    typeof AlertDialogPrimitive.Action
>;

const AlertDialogAction: FC<TAlertDialogActionProps> = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Action>,
    TAlertDialogActionProps
>(({ className, ...props }, ref) => (
    <AlertDialogPrimitive.Action
        ref={ref}
        className={classMerge(buttonVariants(), className)}
        {...props}
    />
));
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;

export type TAlertDialogCancelProps = React.ComponentPropsWithoutRef<
    typeof AlertDialogPrimitive.Cancel
>;

const AlertDialogCancel: FC<TAlertDialogCancelProps> = React.forwardRef<
    React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
    TAlertDialogCancelProps
>(({ className, ...props }, ref) => (
    <AlertDialogPrimitive.Cancel
        ref={ref}
        className={classMerge(
            buttonVariants({ variant: "outline" }),
            "mt-2 sm:mt-0",
            className
        )}
        {...props}
    />
));
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;

export {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
};
