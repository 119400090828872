import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export const FullScreenLayout = () => {
    return (
        <div id="layout" className="h-full w-full">
            <Suspense>
                <Outlet />
            </Suspense>
        </div>
    );
};
