import { authHooks } from "@app/auth";
import { utilsObject } from "@app/common";
import { companyHooks } from "@app/company";
import { UtilsObj } from "@hotelchamp/common";
import React, { useEffect, useState } from "react";
import { config } from "../config";
import { StorageKeys } from "../constants/StorageKeys";
import { INITIAL_APP_STATE } from "../context/AppStateContext";
import { useAppState } from "../hooks";
import { i18next } from "../services/i18n";
import { log } from "../services/log";
import { storageManager } from "../services/storageManager";
import { IAppState } from "../types";

export interface IWithAppStateResolverEnhancerProps {}

export function withAppStateResolver<
    T extends IWithAppStateResolverEnhancerProps = IWithAppStateResolverEnhancerProps
>(WrappedComponent: React.ComponentType<T>) {
    const displayName =
        WrappedComponent.displayName || WrappedComponent.name || "Component";

    const DecoratedComponent = (
        props: Omit<T, keyof IWithAppStateResolverEnhancerProps>
    ) => {
        const [prevAppState, setPrevAppState] = useState<IAppState>();
        const appState = useAppState();
        const { authenticatedUser } = authHooks.useAuth();

        const {
            state: {
                activeLocale,
                adminCompanyId,
                companyId,
                company,
                guestCompany,
                adminCompany,
                subdomain,
                companySlug,
                isLoading,
            },
        } = appState;

        // const { data: loadedAdminCompany } = domainHooks.useGetDomainItem(adminDomainId || 0, { enabled: !!adminDomainId });
        const { data: loadedCompany, isLoading: isCompanyLoading } =
            companyHooks.useGetCompanyItem(companyId || "", {
                enabled: !!companyId,
            });

        const { data: loadedGuestCompany, isLoading: isGuestCompanyLoading } =
            companyHooks.useGetGuestCompanyItem(companySlug || "", {
                enabled: !!companySlug,
            });

        useEffect(() => {
            log.info(`Build ${config.gitTag} - ${config.gitCommitDate}`);
        }, []);

        useEffect(() => {
            const url = new URL(document.location.href);
            const isCompanySlugSubDomain =
                url.hostname !== config.employerDomain;

            if (subdomain && isCompanySlugSubDomain) {
                appState.updateState({ companySlug: subdomain });
            }
        }, []);

        useEffect(() => {
            if (loadedCompany) {
                const nextActiveLocale = loadedCompany.default_language?.code;
                const nextState: Partial<IAppState> = {
                    company: loadedCompany,
                };

                if (!activeLocale && nextActiveLocale) {
                    nextState.activeLocale = nextActiveLocale;
                }

                appState.updateState(nextState);

                setCompanyThemeColors(loadedCompany?.frontend_theme_variables);
            }
        }, [loadedCompany]);

        useEffect(() => {
            const nextIsLoading = isGuestCompanyLoading || isCompanyLoading;

            if (nextIsLoading !== isLoading) {
                appState.updateState({ isLoading: nextIsLoading });
            }
        }, [isGuestCompanyLoading, isCompanyLoading]);

        useEffect(() => {
            const nextActiveLocale = loadedGuestCompany?.default_language?.code;
            const nextState: Partial<IAppState> = {
                guestCompany: loadedGuestCompany,
            };

            if (!activeLocale && nextActiveLocale) {
                nextState.activeLocale = nextActiveLocale;
            }

            appState.updateState(nextState);

            setCompanyThemeColors(loadedGuestCompany?.frontend_theme_variables);
        }, [loadedGuestCompany]);

        useEffect(() => {
            if (activeLocale) {
                i18next.changeLanguage(activeLocale);

                log.info("Changed locale to ", activeLocale);
            }
        }, [activeLocale]);

        useEffect(() => {
            if (!companyId && authenticatedUser) {
                const authenticatedUserCompanyIds = (
                    authenticatedUser.companies || []
                ).map((company) => company.id);

                if (!authenticatedUserCompanyIds.length) {
                    throw new Error(
                        "The authenticated user is not assigned to a company"
                    );
                }

                const matchedGuestCompanyId =
                    guestCompany &&
                    authenticatedUserCompanyIds.includes(guestCompany.id)
                        ? guestCompany.id
                        : null;
                const primaryCompanyId = matchedGuestCompanyId
                    ? matchedGuestCompanyId
                    : authenticatedUserCompanyIds[0];

                if (primaryCompanyId) {
                    appState.updateState({ companyId: primaryCompanyId });
                }
            }
        }, [authenticatedUser]);

        useEffect(() => {
            const diff = UtilsObj.difference(
                prevAppState || {},
                appState.state
            );
            const hasChanges = !!Object.keys(diff).length;

            if (hasChanges) {
                console.log(
                    "AppState changed to ",
                    appState.state,
                    " | Diff (not reliable): ",
                    diff
                );
            }

            setPrevAppState(appState.state);
        }, [appState.state]);

        // set state in storage on change
        useEffect(() => {
            const { activeAppFlow, company, companyId, ...storeableState } =
                appState.state;

            if (!!Object.keys(storeableState).length) {
                // console.log("Persist appState in storage: ", storeableState);

                storageManager
                    .getGlobalSession()
                    .set(StorageKeys.AppState, storeableState);
            }
        }, [appState.state]);

        // restore state from storage initially when component is mount
        useEffect(() => {
            storageManager
                .getGlobalSession()
                .get<IAppState>(StorageKeys.AppState, INITIAL_APP_STATE)
                .then((storageAppState) =>
                    appState.setState((currentState) => {
                        const hasCompanySlugMismatch =
                            storageAppState.company?.slug !== companySlug;
                        const nextState = hasCompanySlugMismatch
                            ? {
                                  ...currentState,
                                  company: undefined,
                                  companyId: undefined,
                              }
                            : { ...currentState };

                        if (storageAppState.company) {
                            setCompanyThemeColors(
                                guestCompany?.frontend_theme_variables
                            );
                        }

                        return utilsObject.merge(currentState, nextState);
                    })
                );
        }, []);

        return <WrappedComponent {...(props as T)} />;
    };

    const setCompanyThemeColors = (colorMapping?: {
        [key: string]: string;
    }) => {
        // const map = {
        //     // gold | #c7a34f | 300, 400, 500
        //     "--theme-cta-primary": "199 163 79",
        //     "--theme-cta-primary-dark": "182 140 60",
        //     "--theme-cta-primary-bright": "211 185 113",

        //     // Red darker | #911f18 | 700, 800, 900
        //     "--theme-primary": "145 31 24",
        //     "--theme-primary-dark": "128 34 28",
        //     "--theme-primary-bright": "187 35 26",

        //     // Red bright | #e6000 | 600, 700, 800
        //     "--theme-secondary": "230 0 0",
        //     "--theme-secondary-dark": "175 5 5",
        //     "--theme-secondary-bright": "252 6 6",

        //     // Red bright | #e6000 | 600, 700, 800
        //     "--theme-tertiary": "230 0 0",
        //     "--theme-tertiary-dark": "175 5 5",
        //     "--theme-tertiary-bright": "252 6 6",

        //     // Red bright | #e6000 | 600, 700, 800
        //     "--theme-gradient-primary-from": "184 33 35", // mid red | b82123 | 700
        //     "--theme-gradient-primary-to": "145 31 24", // Red darker | #911f18 | 800
        // };

        if (colorMapping) {
            Object.keys(colorMapping).forEach((key) => {
                const value = colorMapping[key as keyof typeof colorMapping];

                document.body.style.setProperty(key, value);
            });
        }
    };

    DecoratedComponent.displayName = `withAppStateResolver(${displayName})`;

    return DecoratedComponent;
}
