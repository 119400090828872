import { cva } from "class-variance-authority";

export const badgeVariants = cva(
    "inline-flex items-center rounded-full border text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-theme-ring-primary focus:ring-offset-2",
    {
        variants: {
            variant: {
                default:
                    "border-transparent bg-theme-primary text-primary-foreground hover:bg-theme-primary/80",
                secondary:
                    "border-transparent bg-theme-secondary text-theme-secondary-foreground hover:bg-theme-secondary/80",
                destructive:
                    "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
                outline: "text-foreground",
            },
            size: {
                sm: "px-1.5 py",
                md: "px-2.5 py-0.5",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "md",
        },
    }
);
