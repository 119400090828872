import "@fontsource-variable/inter";
import * as Sentry from "@sentry/react";
import "@worck/ui/src/styles/globals.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import { App, Routes, appConfig, log } from "./app";

Sentry.init({
    dsn: appConfig.sentryDsn,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^https?:\/\/[^/]+\.worck\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App>
            <Routes />
        </App>
    </React.StrictMode>
);

log.info(`Build details ${appConfig.gitTag} - ${appConfig.gitCommitDate}`);

(window as any)._clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();

    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; ++i) {
        const myCookie = cookies[i];
        const pos = myCookie.indexOf("=");
        const name = pos > -1 ? myCookie.substr(0, pos) : myCookie;

        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
};

if (document.location.search.includes("clearStorage")) {
    (window as any)._clearStorage();
}
