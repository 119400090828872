import { axios } from "@app/app";
import { ILead, ITipsterLeadTip, ITipsterVacancy } from "./types";

export const getLead = async (leadId?: string): Promise<ILead> => {
    const response = await axios.get<{ data: ILead }>(`leads/${leadId}`);

    return response.data.data;
};

export const getTipsterVacancy = async (
    tipsterVacancyId: string
): Promise<ITipsterVacancy> => {
    const response = await axios.get<{ data: ITipsterVacancy }>(
        `tipster-vacancies/${tipsterVacancyId}`
    );

    return response.data.data;
};

export const createVacancyTip = async (
    tipsterVacancyId: string,
    data: ITipsterLeadTip
): Promise<void> => {
    await axios.post<void>(
        `tipster-vacancies/${tipsterVacancyId}/tipster-lead-tip`,
        data
    );
};
