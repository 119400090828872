import { authHooks } from "@app/auth";
import { LogoutPage } from "@app/auth/pages/LogoutPages";
import { TipLeadItemPage } from "@app/lead/pages/TipLeadItemPage";
import { HasOneOfRoles, Roles } from "@app/rbac";
import { BoxedSpinner } from "@worck/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Routes as ReactRouterRoutes, Route } from "react-router-dom";
import { useAppState } from "../hooks";
import { FullScreenLayout } from "./layouts/FullScreenLayout";
// import { employerRoutes } from "./routes/employerRoutes";
// import { publisherRoutes } from "./routes/publisherRoutes";
import { TipLeadItemIntroPage } from "@app/lead/pages/TipLeadItemIntroPage";
import { withAppStateResolver } from "./withAppStateResolver";

/**
 * AppStateResolver
 * Helper component to wrap all routes to have top level access to useParams for appState
 */
const AppStateResolver = withAppStateResolver(() => (
    <React.Fragment>
        <Outlet />
    </React.Fragment>
));

export const WithRoleAccessCheck = ({ roles }: { roles: Roles[] }) => {
    const { t } = useTranslation("common");

    return (
        <HasOneOfRoles
            role={roles}
            fallback={
                <div className="h-screen w-screen flex justify-center items-center">
                    <div>
                        <h1 className="text-gray-500 text-3xl">
                            {t("text.not-allowed")}
                        </h1>
                    </div>
                </div>
            }
        >
            <Outlet />
        </HasOneOfRoles>
    );
};

export const RouteContainer = () => <Outlet />;

export const WithAuthInitializationLoader = () => {
    const auth = authHooks.useAuth();

    return !auth.isInitialized ? (
        <div className="h-screen w-screen flex justify-center items-center">
            <div>
                <BoxedSpinner />
            </div>
        </div>
    ) : (
        <Outlet />
    );
};

export function Routes() {
    const auth = authHooks.useAuth();
    const appState = useAppState();
    const { companySlug, activeAppFlow, company, guestCompany } =
        appState.state;
    const companyId = company?.id || guestCompany?.id;

    return (
        <React.Fragment>
            <ReactRouterRoutes>
                <Route element={<AppStateResolver />}>
                    <Route element={<WithAuthInitializationLoader />}>
                        <Route element={<FullScreenLayout />}>
                            <Route
                                path="/:tipsterVacancyShortCode"
                                element={<TipLeadItemIntroPage />}
                            />
                            <Route
                                path="/tip/:tipsterVacancyShortCode"
                                element={<TipLeadItemPage />}
                            />
                        </Route>
                        {/* {activeAppFlow === AppFlows.Employer &&
                            employerRoutes({ auth })} */}
                        {/* {activeAppFlow === AppFlows.Publisher &&
                            publisherRoutes({ auth, companySlug, companyId })} */}

                        <Route path="logout" element={<LogoutPage />} />
                    </Route>
                </Route>
            </ReactRouterRoutes>
        </React.Fragment>
    );
}
