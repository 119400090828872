import { classMerge } from "@worck/ui/src/utils";

export interface ITipSectionHeadingProps {
    title: string | React.ReactNode;
    description?: string | React.ReactNode;
    className?: string;
}

export const TipSectionHeading = ({
    title,
    description,
    className,
}: ITipSectionHeadingProps) => {
    return (
        <div
            className={classMerge(
                "border-b border-gray-200 pb-5 mb-5",
                className
            )}
        >
            <h2 className="text-lg font-semibold">{title}</h2>
            {description && (
                <p className="mt-2 max-w-4xl text-gray-500 text-sm leading-6 font-light">
                    {description}
                </p>
            )}
        </div>
    );
};
