import { ValidationMessageBag } from "@hotelchamp/common";

export class ValidationError<TData> extends Error {
    private messageBag: ValidationMessageBag;

    private data: TData;

    public constructor(
        message: string,
        messageBag: ValidationMessageBag,
        data: TData
    ) {
        super(message);

        this.messageBag = messageBag;
        this.data = data;
    }

    public getMessageBag() {
        return this.messageBag;
    }

    public getData() {
        return this.data;
    }
}
