import { ValidationError, utilsRequest } from "@app/common";
import { IValidationEnginePropertiesRulesConfig } from "@hotelchamp/common";

import { utilsObject } from "@app/common";
import {
    ErrorOption,
    FieldValues,
    ResolverOptions,
    ResolverResult,
} from "react-hook-form";

import { ValidationEngine } from "../services/ValidationEngine";

export const createValidationEngineResolver =
    <TData extends FieldValues>(
        rules: IValidationEnginePropertiesRulesConfig
    ) =>
    (data: TData, context: any, options: ResolverOptions<TData>) => {
        let result: ResolverResult<TData> = { values: data as any, errors: {} };
        const validator = new ValidationEngine(rules).getValidator();

        const validationResult = validator.getResult(data);

        if (!validationResult.isValid()) {
            const messages = validationResult.getMessages();
            const reactHookFormErrors = messages.keys().reduce(
                (aggr: any, key: any) => ({
                    ...aggr,
                    [key]: { message: messages.first(key) },
                }),
                {} as any
            );

            result = {
                values: data,
                errors: reactHookFormErrors,
            };
        }

        return result;
    };

export type TForEachValidationErrorResolvedFromErrorCallback<TFieldKey> = (
    fieldKey: TFieldKey,
    error: ErrorOption
) => void;

export const forEachValidationErrorResolvedFromError = <TFormData>(
    error: any,
    formData: TFormData,
    callback: TForEachValidationErrorResolvedFromErrorCallback<keyof TFormData>
) => {
    const refinedError =
        utilsRequest.resolveRelatedErrorByFailedResponse<TFormData>(
            error as Error,
            formData
        );

    if (refinedError instanceof ValidationError) {
        const messageBag = refinedError.getMessageBag();

        messageBag.keys().forEach(
            (fieldKey) =>
                utilsObject.has(formData, fieldKey) &&
                callback(fieldKey as keyof TFormData, {
                    type: "server",
                    message: messageBag.first(fieldKey),
                })
        );
    }
};
